import $ from "jquery";
import "slick-carousel";

const initSubCategorySlider = function () {
    $(function () {
        $(".autoplay").slick({
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 5,
            infinite: true,

            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 4000,
                        slidesToShow: 3,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 4000,
                        slidesToShow: 2,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 4000,
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 300,
                    settings: "unslick", // destroys slick
                },
            ],
        });

        const $smallProductContainer = $(".small-product-images-container");
        const slideCount = $smallProductContainer.children().length;

        if (slideCount <= 2) {
            $smallProductContainer.css({
                'display': 'flex',
                'justify-content': 'center',
                'gap': '20px'
            });

            $smallProductContainer.find('.small-image-container').css({
                'width': 'calc(50% - 10px)', // Take up roughly half the container width
                'max-width': '300px'
            });
        } else {
            $smallProductContainer.slick({
                autoplay: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 3,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            arrows: true,
                            dots: true
                        }
                    }
                ]
            });
        }
    });
};

export default initSubCategorySlider;
