export default function initSearch() {
    const button = document.querySelector('.search-button');
    const field = document.querySelector('.search-input');
    const form = document.querySelector('.search-form');

    button.addEventListener('click', (e) => {
        e.preventDefault();

        if (field.classList.contains('active')) {
            form.submit();
        } else {
            field.classList.add('active');
            field.focus();
        }
    })
}
