const initSlideshow = function () {
    document.addEventListener("DOMContentLoaded", function () {
        let currentIndex = 0;
        const sliderItems = document.querySelector(".category-items");
        var itemWidth = 0;
        if (document.querySelector(".cat-item")) {
            var itemWidth =
                document.querySelector(".cat-item").offsetWidth + 25;
        }
        var totalItems = 0;
        if (sliderItems) {
            var totalItems = sliderItems.children.length;
        }

        if (document.querySelector(".next")) {
            document.querySelector(".next").addEventListener("click", () => {
                console.log(itemWidth);
                if (currentIndex < totalItems - 6) {
                    console.log(itemWidth);
                    currentIndex++; // Move to the next item (slide one item forward)
                    sliderItems.style.transform = `translateX(-${
                        currentIndex * itemWidth
                    }px)`;
                }
            });
        }

        if (document.querySelector(".prev")) {
            document.querySelector(".prev").addEventListener("click", () => {
                if (currentIndex > 0) {
                    currentIndex--;
                    sliderItems.style.transform = `translateX(-${
                        currentIndex * itemWidth
                    }px)`;
                }
            });
        }
    });
};

export default initSlideshow;
