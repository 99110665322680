const initProduct = function () {
    document.addEventListener("DOMContentLoaded", function () {
        // Existing variables
        const variantSelect = document.getElementById('variant-select');
        const priceDisplay = document.getElementById('variant-price');
        const quantityInput = document.querySelector('input[name="quantity"]');
        const submitButton = document.getElementById('order-button');
        const stockAlert = document.getElementById('stock-alert');
        const alert = document.getElementById('alert');
        const errorAlert = document.getElementById('error-alert');
        const alertDuration = 6000;
        var urlParams = new URLSearchParams(window.location.search);
        var addedToCart = urlParams.get('added_to_cart');

        // Image navigation variables
        const heroImage = document.getElementById('hero-image');
        const thumbnails = document.querySelectorAll('.thumbnail');
        const prevBtn = document.querySelector('.prev-btn');
        const nextBtn = document.querySelector('.next-btn');
        let currentIndex = 0;

        const getCurrentStock = () => {
            if (variantSelect) {
                const selectedOption = variantSelect.options[variantSelect.selectedIndex];
                return parseInt(selectedOption.getAttribute('data-stock'));
            }

            const stockElement = standardVariant;
            return stockElement ? parseInt(stockElement.getAttribute('data-stock')) : 0;
        };

        const validateQuantity = () => {
            const currentStock = getCurrentStock();
            const quantity = parseInt(quantityInput.value);

            if (quantity > currentStock) {
                stockAlert.textContent = `Er zijn slecht ${currentStock} stuks van dit product op voorraad`;
                stockAlert.style.display = 'block';
                return false;
            }

            stockAlert.style.display = 'none';
            return true;
        };

        if (errorAlert) {
            errorAlert.style.display = 'block';
            setTimeout(function () {
                errorAlert.style.display = 'none';
            }, alertDuration + 10000);
        }

        if (quantityInput) {
            quantityInput.addEventListener('change', validateQuantity);
            quantityInput.addEventListener('input', validateQuantity);
        }

        if (variantSelect) {
            variantSelect.addEventListener('change', function (e) {
                priceDisplay.textContent = this.options[this.selectedIndex].getAttribute("data-price");
                validateQuantity();
            });
        }

        // Image navigation functions
        if (heroImage && thumbnails.length > 0 && prevBtn && nextBtn) {
            const updateImage = (index) => {
                // Remove active class from all thumbnails
                thumbnails.forEach(thumb => {
                    thumb.classList.remove('active');
                });

                // Add active class to current thumbnail
                thumbnails[index].classList.add('active');

                // Update hero image
                heroImage.src = thumbnails[index].getAttribute('src');
                currentIndex = index;
            };

            // Event listeners for navigation buttons
            prevBtn.addEventListener('click', () => {
                let newIndex = currentIndex - 1;
                if (newIndex < 0) {
                    newIndex = thumbnails.length - 1;
                }
                updateImage(newIndex);
            });

            nextBtn.addEventListener('click', () => {
                let newIndex = currentIndex + 1;
                if (newIndex >= thumbnails.length) {
                    newIndex = 0;
                }
                updateImage(newIndex);
            });

            // Add click handlers to thumbnails
            thumbnails.forEach((thumbnail, index) => {
                thumbnail.addEventListener('click', () => {
                    updateImage(index);
                });
            });

            // Initialize first image as active
            updateImage(0);
        }

        if (variantSelect) {
            variantSelect.addEventListener('change', function (e) {
                priceDisplay.textContent = variantSelect.options[variantSelect.selectedIndex].getAttribute("data-price");
            });
        }

        //------------------------------ Add to cart alerts -------------------------------//
        function removeQueryParameter(param) {
            var url = new URL(window.location.href);
            url.searchParams.delete(param);
            window.history.replaceState({}, '', url);
        }


        if (addedToCart === 'true') {
            if (alert) {
                alert.style.display = 'block';
                setTimeout(function () {
                    alert.style.display = 'none';
                }, alertDuration);
                setTimeout(function () {
                    removeQueryParameter('added_to_cart');
                }, alertDuration);
            }
        } else if (addedToCart === 'false' && errorAlert) {
            errorAlert.style.display = 'block';
            setTimeout(() => {
                errorAlert.style.display = 'none';
            }, alertDuration + 5000 );
            setTimeout(() => {
                removeQueryParameter('added_to_cart');
            }, alertDuration);
        }
    });
}

export default initProduct;
