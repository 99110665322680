const initScroll = function () {
    document.addEventListener("DOMContentLoaded", function () {
        const scrollBtn = document.getElementById("scroll-btn");

        if ( !scrollBtn ) {
            return;
        }

        window.onscroll = function() {scrollFunction()};

        function scrollFunction() {

            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollBtn.style.display = "block";
            } else {
                scrollBtn.style.display = "none";
            }
        }

        scrollBtn.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })

    });
}


export default initScroll;
