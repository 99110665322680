const initLogin = function () {
    document.addEventListener("DOMContentLoaded", function () {
        const forgot = document.getElementById('forgot');
        const reset = document.getElementById('reset');


    });
}

export default initLogin;
