const initCart = function () {
    document.addEventListener("DOMContentLoaded", function () {
        const quantityInputs = document.querySelectorAll('.input-amount');
        const radio = document.querySelectorAll(`input[name="use_shipping_address_for_billing"]`)
        const newAddress = document.getElementById('new-address');

        quantityInputs.forEach(function (input) {
            input.addEventListener('input', function () {
                this.form.submit();
            });
        });


       /* document.getElementById('checkout-btn').addEventListener('click', function() {
            const currentUrl = window.location.href;
            let redirectUrl;

            if (currentUrl.includes('/winkelwagen')) {
                redirectUrl = '/checkout';
                window.location.href = redirectUrl;
            }*/

        // });

        radio.forEach(function (radio) {
            radio.addEventListener('change', function () {
                if (radio.checked) {
                    newAddress.style.display = 'block';
                    document.querySelector('input[name=billing_firstname]').required = true;
                    document.querySelector('input[name=billing_lastname]').required = true;
                    document.querySelector('input[name=billing_street]').required = true;
                    document.querySelector('input[name=billing_number]').required = true;
                    document.querySelector('input[name=billing_postal_code]').required = true;
                    document.querySelector('input[name=billing_city]').required = true;
                    document.querySelector('select[name=billing_country]').required = true;
                } else {
                    newAddress.style.display = 'none';
                    document.querySelector('input[name=billing_firstname]').required = false;
                    document.querySelector('input[name=billing_lastname]').required = false;
                    document.querySelector('input[name=billing_street]').required = false;
                    document.querySelector('input[name=billing_number]').required = false;
                    document.querySelector('input[name=billing_postal_code]').required = false;
                    document.querySelector('input[name=billing_city]').required = false;
                    document.querySelector('select[name=billing_country]').required = false;

                }
            });
        });

    });
}

export default initCart;
