const initCheckout = function () {
    document.addEventListener("DOMContentLoaded", function () {
        const radio = document.querySelectorAll(`input[name="use_shipping_address_for_billing"]`)
        const newAddress = document.getElementById('new-address');
        const checkoutBtn = document.getElementById('checkout-btn');
        const selectPayment = document.getElementById('gateway');
        const cardInput = document.getElementById('payment-input');

        if (checkoutBtn) {
        checkoutBtn.addEventListener('click', function () {
            const currentUrl = window.location.href;
            let redirectUrl;

            if (currentUrl.includes('winkelwagen')) {
                redirectUrl = '/checkout';
                window.location.href = redirectUrl;
            } else if (currentUrl.includes('/checkout/ontvangen')) {
                redirectUrl = '/betaling';
                window.location.href = redirectUrl;
            } else if (currentUrl.includes('/betaling')) {
                console.log("klik");
            }
        });
        }

        if (selectPayment) {
        selectPayment?.addEventListener('change', function () {
            if (this.value === 'dummy' || this.value === 'Dummy') {
                cardInput.style.display = 'block'
            } else {
                cardInput.style.display = 'none'

            }
        })
        }

/*
        radio.forEach(function (radio) {
            radio.addEventListener('change', function () {
                if (radio.checked) {
                    newAddress.style.display = 'block';
                } else {
                    newAddress.style.display = 'none';
                    document.querySelectorAll('[name^="billing_"]').forEach(function (billingField) {
                        const shippingField = document.querySelector(`[name="${billingField.name.replace('billing_', 'shipping_')}"]`);
                        if (shippingField) billingField.value = shippingField.value;
                    });
                }
            });
        });*/
    });

}

export default initCheckout;

