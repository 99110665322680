import * as CookieConsent from 'vanilla-cookieconsent';

export default function initCookieConsent() {
    const listenForConsent = () => {

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'granted',
        });


        const updateGtagConsent = () => {
            gtag('consent', 'update', {
                'ad_storage': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
                'ad_user_data': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
                'ad_personalization': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
                'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
                'functionality_storage': CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
                'personalization_storage': CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
                'security_storage': 'granted', //necessary
            });
        };

        window.addEventListener('cc:onConsent', () => {
            updateGtagConsent();
        });

        window.addEventListener('cc:onChange', () => {
            updateGtagConsent();
        });
    };

    window.addEventListener("load", function () {
        CookieConsent.run({
            disablePageInteraction: true,
            guiOptions: {
                consentModal: {
                    layout: 'cloud',
                    position: 'top'
                }
            },
            language: {
                default: 'nl',
                translations: {
                    'nl': {
                        consentModal: {
                            title: "Jouw privacyvoorkeuren",
                            description: "Wij maken gebruik van cookies om gepersonaliseerde advertenties te tonen en verkeer op onze website te analyseren. Ook delen wij deze informatie met onze social media, advertentie en analyse-partners. Zij kunnen dit eventueel combineren met andere data die ze over jou hebben verzameld.",
                            acceptAllBtn: "Alles accepteren",
                            acceptNecessaryBtn: "Alles weigeren",
                            showPreferencesBtn: "Voorkeuren aanpassen"
                        },
                        preferencesModal: {
                            title: "Cookie voorkeuren",
                            acceptAllBtn: "Alles accepteren",
                            acceptNecessaryBtn: "Alles weigeren",
                            savePreferencesBtn: "Voorkeuren opslaan",
                            closeIconLabel: "Sluiten",
                            sections: [
                                {
                                    title: "Jouw privacyvoorkeuren",
                                    description: "Wij maken gebruik van cookies om gepersonaliseerde advertenties te tonen en verkeer op onze website te analyseren. Ook delen wij deze informatie met onze social media, advertentie en analyse-partners. Zij kunnen dit eventueel combineren met andere data die ze over jou hebben verzameld."
                                },
                                {
                                    title: "Verplicht",
                                    description: "Deze cookies zijn verplicht. Zonder deze cookies werkt de website niet naar behoren.",
                                    linkedCategory: "necessary"
                                },
                                {
                                    title: "Analytics",
                                    description: "Deze cookies geven ons inzicht in hoe gebruikers met de website omgaan.",
                                    linkedCategory: "analytics",
                                },
                                {
                                    title: "Advertenties",
                                    description: "Deze cookies geven ons inzicht in onze advertenties en zorgen voor gepersonaliserde advertenties.",
                                    linkedCategory: "advertisement",
                                },
                                {
                                    title: "Meer informatie",
                                    description: "Voor meer informatie, kijk op <a class=\"cc-link\" href=\"https://www.zinaantafel.nl/privacybeleid\">onze privacypagina</a>."
                                }
                            ]
                        }
                    }
                }
            },
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                },
                functional: {},
                analytics: {},
                // performance: {},
                advertisement: {}
            },
        });
        listenForConsent();
    });
}
