const initFilter = function () {
    document.addEventListener("DOMContentLoaded", function () {
        // const categoryLinks = document.querySelectorAll('.filter-categories a');
        const slider = document.querySelector("#rangeSlider");
        const form = document.querySelector("#productFilter");

        if (!slider || !form) {
            return;
        }
        const minValue = document.getElementById('minValue');
        const maxValue = document.getElementById('maxValue');
        const price = slider.value;


        slider.addEventListener('input', setBackground);
        setBackground();

        function setBackground() {
            const value = ((slider.value - slider.min) / (slider.max - slider.min)) * 100;
            maxValue.textContent = `€${slider.value}`;
            slider.style.background = `linear-gradient(to right, #A475C6 ${value}%, #ddd ${value}%)`;
        }

        const toggle = document.getElementById('toggle-filter');

        if ( !toggle ) {
            return;
        }

        toggle.addEventListener('click', () => {
            form.classList.add('active');
        });

        window.submitFilter = () => {
            console.log('TEST');
            form.submit();
        }
    });
}


export default initFilter;
