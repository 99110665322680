import initCart from './components/cart.js';
import initCheckout from './components/checkout.js';
import initFilter from './components/filter.js';
import initProduct from './components/product.js';
import initLogin from './components/login.js';
import initSubCategorySlider from './components/subCategorySlider.js';
import initSlideshow from "./components/slideshow.js";
import initScroll from './components/scroll.js';
import initHamburger from "./components/hamburger.js";
import initSearch from "./components/search.js";
import initCookieConsent from "./components/cookieconsent.js";

initSearch();
initCart();
initCheckout();
initFilter();
initProduct();
initLogin();
initSubCategorySlider();
initSlideshow();
initScroll();
initHamburger();
initCookieConsent();
