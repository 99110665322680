import Mmenu from 'mmenu-js';

const initHamburger = function () {
    document.addEventListener("DOMContentLoaded", () => {

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

        if ( vw > 767 ) {
            return;
        }

        const menu = new Mmenu(".nav-category", {
            "offCanvas": {
                "position": "left"
            },
            scrollBugFix: {
                fix: true,
            },
            "theme": "light"
        });


        document.getElementById("mmenu-toggle").addEventListener("click", () => {
            if ( menu.node.menu.classList.contains('mm-menu--opened')) {
                console.log("closing menu");
                menu.API.close()
            } else {
                console.log("opening menu");
                menu.API.open()
            }
        });
    });
}


export default initHamburger;
